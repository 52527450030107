@import "~@silvermine/videojs-chromecast/dist/silvermine-videojs-chromecast.css";
@import "./variables";

.App section:nth-child(1) { 
  @media (min-width: 1000px) {
    height: 100vh;
  }
}

.App {
  .MiniPlayerContainer-dragContainer {
    height: fit-content;
    position: absolute;
    right: 5px;
    bottom: 70px;
    width: calc(100vw - 10px);
  }

  .SuspenseLoader {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }

  @media (min-width: 1000px) {
    .MiniPlayerContainer-dragContainer {
      right: 50px;
      bottom: 150px;
      width: 700px;
    }
  }
}

.NavigationLink {
  color: $link-color;
  cursor: pointer;
}
