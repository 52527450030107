@import '../../../../../../../../variables';

.RoyaltiesView {   
  .ErrorMessage {
    font-size: 1.2em;
    font-weight: 600;
    text-align: center;
    padding: 50px 10px;
    color: #000;
  }

  .MoviesCount {
    font-weight: 700;
    text-decoration: underline;
  } 

  .ant-table {
    overflow: auto;

    @media (max-width: $breakpoint-mobile) { 
      td, th {
        white-space: nowrap;
      }
    }
  }

  .ant-table-tbody {
    background-color: #fff;
  }
}
