.PageInput {
  display: flex; 
  align-items: center;

  .ant-input-number-handler-wrap {
    display: none;
  }

  .InvalidInput {
    border: 1px dotted red;
  }

  .anticon {
    font-size: 15px;
  }

  input {
    flex: 1;
    margin-right: 5px;
    max-width: 80px;
  }  
 
  &>* {
    margin: 0 10px;
  } 
}
