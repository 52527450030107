.MainContent {
  //overflow: hidden;

  &.ant-layout-content {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    > :first-child {
      flex: auto;
      min-height: 0;
      display: flex;
      flex-direction: column;
    }
  }
}
