@import "../../variables";

.LinkButton {
  border: 1px solid $purple-light; 
  padding: 5px 15px;
  font-size: 14px;
  border-radius: 3px;
  color: rgba(0, 0, 0, 0.65);
  background-color: $white;

  > .anticon {
    margin-right: 5px;
  }
}
