@import "../../../../../../../../../variables";

.Header {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-weight: bold;
  padding: 10px;

  &>* {
    margin: 0 5px;
  }
}