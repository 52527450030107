@import '../../../../../../../../variables';

.Tabs {
    background-color: #fff; 

    .LinkButton {
        display: inline-block; 
        color: $base-navigation-subtitle-color;
        background: rgba(240, 242, 245, 1); 
        font-weight: 700;
        text-transform: uppercase;
        border: none;
        border-bottom: 3px solid transparent;
        transition: none;
        
        &:hover,
        &.active {  
            color: $base-navigation-title-color; 
            border-color:$base-navigation-title-color; 
        }
    }
}