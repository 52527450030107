@import '../../variables';

.BaseMultipleRouteNavigationItem {
  padding: 10px;

  .Breadcrumb-link,
  .Breadcrumb-text {
    text-transform: uppercase;
    font-weight: bold; 
    display: inline-block;
  }

  .Breadcrumb-text {
    color: $base-navigation-subtitle-color;
  }

  .Breadcrumb-link {
    color: $base-navigation-title-color;

    &:after {
      content: '';
      display: inline-block;
      margin: 0 10px;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-left: 16px solid $base-navigation-arrow-color;;
    }
  }
}